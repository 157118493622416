export class ViewActivityService {
  userService;
  activityService;

  constructor(activityService, userService) {
    this.activityService = activityService;
    this.userService = userService;
  }

  async loadDops(activityId, dopFunctionId) {
    let allDops = [];
    let dopsWithNoMoreDopFunction = [];
    let dopsOut = [];

    let dops = await this.activityService.getDopsOfActivity(activityId);
    console.log(JSON.stringify(dops));

    // Boucle de récupération d'un utilisateur et association des datas d'un dop d'activité
    for (const element of dops) {
      // Récupération des datas user
      let user = await this.userService.getByUuid(element.manager);

      // Association des datas dops activité
      let dop = {};
      dop.idDop = element.manager;
      dop.fullname = `${user.person?.firstname} ${user.person?.name}`;
      dop.label =
        !user.out && user.gsuiteaccount
          ? user.gsuiteaccount.email
          : dop.fullname;
      dop.perimeter = element.perimeter;
      dop.error = false;

      // si le dop est sorti, il est en erreur
      if (user.out || !user.gsuiteaccount) {
        dop.error = true;
        dop.label = dop.fullname + " <i>(n'est plus collaborateur)</i>";
        dopsOut.push(dop.fullname);
      }

      // Si le dop n'est pas déjà en erreur car sorti, et qu'il n'a plus la fonction dop, il est en erreur
      if (!dop.error && !this.isDopByHisFunction(user, dopFunctionId)) {
        dop.error = true;
        dop.label = dop.fullname + " <i>(n'est plus dop)</i>";
        dopsWithNoMoreDopFunction.push(dop.fullname);
      }
      allDops.push(dop);
    }
    return {
      allDops,
      dopsWithNoMoreDopFunction,
      dopsOut,
    };
  }

  isDopByHisFunction(user, dopFunctionId) {
    return (
      user.function == dopFunctionId ||
      user.secondaryFunctions.includes(dopFunctionId)
    );
  }

  getDopErrorMessage(dopsWithNoMoreDopFunction, dopsOut) {
    let errorMessages = [];
    if (dopsWithNoMoreDopFunction.length > 1) {
      errorMessages.push(
        "Les collaborateurs " +
          dopsWithNoMoreDopFunction.join(", ") +
          " n'ont plus la fonction 'dop' mais sont toujours associés à cette activité."
      );
    } else if (dopsWithNoMoreDopFunction.length == 1) {
      errorMessages.push(
        "Le collaborateur " +
          dopsWithNoMoreDopFunction[0] +
          " n'a plus la fonction 'dop' mais est toujours associé à cette activité."
      );
    }
    if (dopsOut.length > 1) {
      errorMessages.push(
        "Les DOPs " + dopsOut.join(", ") + " ne sont plus collaborateurs."
      );
    } else if (dopsOut.length == 1) {
      errorMessages.push("Le DOP " + dopsOut[0] + " n'est plus collaborateur.");
    }

    let result = undefined;
    if (errorMessages.length === 1) {
      result = errorMessages[0];
    } else if (errorMessages.length > 1) {
      result = errorMessages.join("<br/>");
    }
    return result;
  }
}
